<template>
  <div>
    <!-- table-box -->
    <div>
      <div class="rtl-right search-box">
        <el-row :gutter="5">
          <el-col :span="8">
          <el-date-picker
            style="width:100%"
            class="ltr-left"
            v-model="filterForm.timeRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="-"
            unlink-panels
            @change="toSearch()"
            start-placeholder="باشلىنىش ۋاقتى"
            end-placeholder="ئاخىرلىشىش ۋاقتى">
          </el-date-picker>
        </el-col>
          <el-col :span="4">
            <el-button type="success" plain icon="el-icon-search" @click="toSearch()">ئىزدەش </el-button>
            <el-button type="danger" plain icon="el-icon-circle-close" @click="toClear()">بىكار  قىلىش </el-button>
          </el-col>
        </el-row>
      </div>

      <div class="table-box">
        <el-table
          :data="tableData" 
          border 
          style="width: 100%" 
          v-loading="loading">
          <el-table-column type="index" label="#"></el-table-column>
          <el-table-column prop="name_ug" label="دەرىجە ئىسمى"></el-table-column>
          <el-table-column prop="level" label="دەرىجىسى"></el-table-column>
          <el-table-column prop="price" label="باھاسى"></el-table-column>
          <el-table-column prop="total_count" label="سىتىلغان سانى"></el-table-column>
          <el-table-column prop="total_price" label="سىتىلغان سومما"></el-table-column>
          <el-table-column prop="agents_profit" label="ۋاكالەتچى پايدىسى"></el-table-column>
          <el-table-column prop="platform_profit" label="دەرسخانا پايدىسى"></el-table-column>
        </el-table>
      </div>

      <el-row style="padding: 40px 0;">
        <el-col :span="24">
          <div class="rtl-right total-box">
            <p style="color: #409EFF;">
              <label>سىتىلغان ئومومىي سانى</label>
              <span>{{totalData.total_count}}</span>
            </p>
            <p style="color: #67C23A;">
              <label>سىتىلغان ئومومىي سومما</label>
              <span>{{totalData.total_price}}</span>
            </p>
            <p style="color: #F56C6C;">
              <label>ۋاكالەتچى ئومومىي پايدىسى</label>
              <span>{{totalData.agents_profit}}</span>
            </p>
            <p style="color: #FA7922;">
              <label>دەرسخانا ئومومىي پايدىسى</label>
              <span>{{totalData.platform_profit}}</span>
            </p>
          </div>
        </el-col>
      </el-row>
      
    </div>
  </div>
</template>

<script>
  var self;
  
  export default {
    activated: function() {
      self = this;
      document.querySelector("a[href='/statistics']").className = 'active-menu';
      self.getList();
      self.getTotalData();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        pageTags:{},
        filterForm:{
          timeRange:[]
        },
        totalData:{}
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/statistic/agents",{
          start_date:self.filterForm.timeRange?self.filterForm.timeRange[0]:null,
          end_date:self.filterForm.timeRange?self.filterForm.timeRange[1]:null
        }).then(response => {
          if (response.status == 200) {
            self.tableData = response.data.data;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      toSearch(){
        self.current_page = 1;
        self.getList();
        self.getTotalData();
      },
      toClear(){
        self.filterForm = {
          timeRange:[]
        }
        self.toSearch();
      },
      getTotalData(){
        self.$fetch("admin/statistic/agents",{
          type:1,
          start_date:self.filterForm.timeRange?self.filterForm.timeRange[0]:null,
          end_date:self.filterForm.timeRange?self.filterForm.timeRange[1]:null
        }).then(response => {
          if (response.status == 200) {
            self.totalData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .search-box{
    .el-select,.el-input,.el-date-editor{
      width: 100%;
    }
    [class*="el-col-"]{
      float: right;
    }
  }
  .total-box{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    p{
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      label{
        font-size: 18px;
        white-space: nowrap;
        margin-left: 20px;
        &:after{
          content: ':';
        }
      }
      span{
        font-size: 20px;
      }
      &:not(:last-child){
        margin-left: 80px;
      }
    }
  }
</style>

